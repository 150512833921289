<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      max-width="800"
      @click:outside="init"
      @keydown="$event.key === 'Escape' ? init() : ''"
    >
      <v-card>
        <v-card-title class="d-flex align-center pt-6 justify-space-between">
          <span class="text-h5">
            {{ type === 1 ? '환불' : '매출'}}등록
          </span>
          <!-- 환불등록인 경우 -->
          <div
            class="picker-wrap"
            v-if="type === 1"
          >
            <v-btn
              color="success"
              elevation
              @click="salesPicker = true"
            >
              매출정보 불러오기
            </v-btn>
            <v-card
              class="search-picker"
              v-click-outside="salesPickerInit"
              v-if="salesPicker === true"
            >
              <v-text-field
                :autofocus="true"
                dense
                flat
                hide-details
                v-model="saleText"
                placeholder="고객명, 매출번호로 검색"
                @keydown.enter="getSalesList"
                color="black"
                outlined
                solo
              />
              <v-btn
                class="search-btn"
                elevation
                color="primary"
                small
                @click="getSalesList"
              >
                검색
              </v-btn>
              <v-list v-if="sales.length > 0">
                <v-list-item
                  v-for="(row, i) in sales"
                  :key="i"
                  dense
                  nav
                  link
                  @click="setSales(row.no)"
                >
                  <v-list-item-title>
                    <v-chip
                      small
                      class="pr-1 pl-1 mr-1"
                      link
                      outlined
                      label
                    >
                      {{ `매출번호: ${row.no}` }}
                    </v-chip>
                    <v-chip
                      small
                      class="pr-1 pl-1"
                      link
                      outlined
                      label
                    >
                      {{ `${row.customerName} (${row.customerPhone.slice(-4)})` }}
                    </v-chip>
                    {{ `${comm.priceToString(row.amount)}` }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list v-else-if="sales.length === 0">
                <v-list-item dense>
                  <v-list-item-title>데이터가 없습니다.</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </div>
          <!-- 환불등록인 경우 끝 -->
        </v-card-title>
        <!-- 매출등록 시작 -->
        <v-card-text
          class="content-wrap"
          v-if="type === 0"
        >
          <div class="content-box">
            <!-- 고객 -->
            <div
              class="section-wrap"
              v-if="customer !== null"
            >
              <div class="section">
                <p class="tit">고객</p>
                <v-chip
                  label
                  @click="customer = null"
                  @click:close="customer = null"
                  link
                  close
                >
                  {{ `${customer.name} (${comm.makePrivatePhoneNumber(customer.phone)})` }}
                </v-chip>
              </div>
              <div class="section">
                <p class="tit">영업담당자</p>
                <div>
                  {{ manager.fullName }}
                  <v-card
                    class="search-picker"
                    v-click-outside="managerPickerInit"
                    v-if="managerPicker === true"
                  >
                    <v-text-field
                      :autofocus="true"
                      dense
                      flat
                      hide-details
                      v-model="managerText"
                      placeholder="영업담당자 검색"
                      @input="managerFiltering"
                      color="black"
                      outlined
                      solo
                    ></v-text-field>
                    <v-list v-if="managerFilter.length > 0">
                      <v-list-item
                        v-for="(row, i) in managerFilter"
                        :key="i"
                        dense
                        nav
                        link
                        @click="manager = row, managerPickerInit()"
                      >
                        <v-list-item-title>
                          <v-chip
                            small
                            class="pr-1 pl-1 mr-1"
                            link
                            outlined
                            label
                          >
                            {{ row.group }}
                          </v-chip>
                          {{ row.name }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <v-list v-else-if="managerFilter.length === 0">
                      <v-list-item dense>
                        <v-list-item-title>{{
                          managerText == null
                          || managerText.trim().length === 0
                          ?'검색어를 입력해주세요.'
                          :'데이터가 없습니다.'
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                  <v-btn
                    small
                    elevation
                    class="mb-1 ml-2"
                    v-if="
                      checkFuncPermission('manager') === true
                      && isManager !== true && isLeader !== true
                    "
                    @click="getManagerList(), managerPicker = true"
                  >
                    변경
                  </v-btn>
                </div>
              </div>
            </div>

            <!-- 고객null -->
            <div
              class="section"
              v-else
            >
              <p class="tit">고객</p>
              <v-card
                class="search-picker"
                v-click-outside="customerPickerInit"
                v-if="customerPicker === true"
              >
                <v-text-field
                  :autofocus="true"
                  dense
                  flat
                  hide-details
                  v-model="customerText"
                  @keydown.enter="getCustomerList()"
                  placeholder="고객명/전화번호 입력 후 엔터"
                  color="black"
                  outlined
                  solo
                ></v-text-field>
                <v-list v-if="customers.length > 0">
                  <v-list-item
                    v-for="(row, i) in customers"
                    :key="i"
                    dense
                    nav
                    link
                    @click="customerSetter(row)"
                  >
                    <v-list-item-title>
                      <v-chip
                        small
                        class="pr-1 pl-1 mr-1"
                        link
                        outlined
                        label
                      >
                        {{comm.makePrivatePhoneNumber(row.phone)}}
                      </v-chip>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="customers.length === 0">
                  <v-list-item dense>
                    <v-list-item-title>검색어 입력 후 엔터를 눌러주세요.</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="customerPicker = true"
                    dense
                    depressed
                    fab
                    x-small
                    dark
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>고객 선택</span>
              </v-tooltip>
            </div>

            <!-- 상품 -->
            <div
              class="section-wrap"
              v-if="product != null"
            >
              <div class="section">
                <p class="tit">상품</p>
                <v-chip
                  label
                  @click="product = null"
                  @click:close="product = null"
                  link
                  close
                >
                  {{ product.name }}
                </v-chip>
              </div>
              <div class="section">
                <p class="tit">상품가격</p>
                {{ comm.priceToString(product.price) }}
              </div>
              <div
                class="section"
                v-if="product.type === 0"
              >
                <p class="tit">상품이용기간</p>
                <span>
                  {{ product.term }}개월
                  <span
                    class="info--text"
                    v-if="product.freeTerm > 0"
                  >
                    + {{ product.freeTerm }}개월
                  </span>
                </span>
              </div>
              <div
                class="section"
                v-else
              >
                <p class="tit">수량</p>
                <div class="text-input-wrap">
                  <v-text-field
                    v-model="productQuantity"
                    outlined
                    dense
                    hide-details
                    label="수량"
                    :rules="[quantityRule]"
                  />
                </div>
              </div>
              <div
                class="section"
                v-if="product.type === 0"
              >
                <p class="tit flex-shrink-0">리딩방</p>
                <div
                  class="d-flex flex-wrap"
                  v-if="product.rooms.length > 0"
                >
                  <v-chip
                    v-for="(room, index) in product.rooms"
                    :key="index"
                    outlined
                    label
                    small
                    class="ml-2 my-1"
                  >
                    {{ room.name }}
                  </v-chip>
                </div>
                <span v-else>상품에 적용된 리딩방이 없습니다.</span>
              </div>
              <div
                class="section"
                v-else
              >
                <p class="tit">수량</p>
                <div class="text-input-wrap">
                  <v-text-field
                    v-model="productQuantity"
                    outlined
                    dense
                    hide-details
                    label="수량"
                    :rules="[quantityRule]"
                  />
                </div>
              </div>
            </div>
            <!-- 상품null -->
            <div
              class="section"
              v-else
            >
              <p class="tit">상품</p>
              <v-card
                class="search-picker"
                v-click-outside="productPickerInit"
                v-if="productPicker === true"
              >
                <v-text-field
                  :autofocus="true"
                  dense
                  flat
                  hide-details
                  v-model="productText"
                  placeholder="상품명 검색"
                  @input="productFiltering"
                  color="black"
                  outlined
                  solo
                ></v-text-field>
                <v-list v-if="productFilter.length > 0">
                  <v-list-item
                    v-for="(row, i) in productFilter"
                    :key="i"
                    dense
                    nav
                    link
                    @click="
                      setProduct(row);
                      productPickerInit()
                    "
                  >
                    <v-list-item-title>
                      <v-chip
                        v-if="row.type === 0"
                        small
                        class="pr-1 pl-1 mr-1"
                        color="info"
                        link
                        label
                      >
                        리딩
                      </v-chip>
                      <v-chip
                        v-else-if="row.type === 1"
                        small
                        class="pr-1 pl-1 mr-1"
                        color="warning"
                        link
                        label
                      >
                        코인
                      </v-chip>
                      {{ row.name }}
                      <v-chip
                        small
                        class="pr-1 pl-1 ml-1"
                        link
                        outlined
                        label
                      >
                        {{ comm.priceToString(row.price) }}
                      </v-chip>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="productFilter.length === 0">
                  <v-list-item dense>
                    <v-list-item-title>{{
                      productText == null
                      || productText.trim().length === 0
                      ?'검색어를 입력해주세요.'
                      :'데이터가 없습니다.'
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="getProductList(), productPicker = true"
                    dense
                    depressed
                    fab
                    x-small
                    dark
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>상품 선택</span>
              </v-tooltip>
            </div>

            <!-- 판매가조정 -->
            <div class="section">
              <p class="tit">판매가조정</p>
              <div class="input-wrap">
                <v-radio-group
                  @change="discountRadio = $event"
                  dense
                  row
                  :value="discountRadio"
                >
                  <v-radio
                    label="할인"
                    :value="-1"
                    class="mr-4"
                  ></v-radio>
                  <v-radio
                    label="추가"
                    :value="1"
                    class="mr-4"
                  ></v-radio>
                </v-radio-group>
                <div class="text-input-wrap">
                  <price-text
                    v-model="discount"
                    outlined
                    dense
                    label="금액(원)"
                    :rules="[priceRule]"
                    hide-details
                  />
                </div>
              </div>
            </div>

            <!-- 계약서 -->
            <div class="section">
              <input
                type="file"
                accept=".pdf, image/*"
                @change="fileSetter($event, 'saleDoc')"
                class="file-input"
                ref="saleDocInput"
              />
              <p class="tit">계약서</p>
              <v-tooltip
                top
                color="black"
                v-if="saleDoc === null"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="$refs.saleDocInput.value=null, $refs.saleDocInput.click()"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    depressed
                    fab
                    x-small
                    dark
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>계약서 첨부</span>
              </v-tooltip>
              <v-chip
                v-else
                label
                @click="$refs.saleDocInput.value=null, saleDoc=null"
                @click:close="$refs.saleDocInput.value=null, saleDoc=null"
                link
                close
              >
                {{ saleDoc.name }}
              </v-chip>
            </div>

            <!-- 결제방법선택 -->
            <div
              class="section"
              v-if="product !== null && product.type === 0"
            >
              <p class="tit">
                결제방법
              </p>
              <div class="input-wrap">
                <v-radio-group
                  @change="methodRadio = $event"
                  dense
                  row
                  :value="methodRadio"
                >
                  <v-radio
                    label="현금"
                    :value="0"
                    class="mr-4"
                  ></v-radio>
                  <v-radio
                    label="카드"
                    :value="1"
                    class="mr-4"
                  ></v-radio>
                </v-radio-group>
              </div>
            </div>
            <!-- 결제정보등록 -->
            <div class="section">
              <p class="tit">
                결제정보
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="question"
                      small
                      color="warning"
                      v-on="on"
                      v-bind="attrs"
                    >mdi-help-circle-outline</v-icon>
                  </template>
                  <span>
                    입력한 결제정보로 담당자가 대행결제/입금확인을 진행합니다.<br />
                    정확한 정보를 입력해주세요.
                  </span>
                </v-tooltip>
              </p>
              <v-tooltip
                top
                color="black"
                v-if="payments.length < 5"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="paymentAppenderInit(); paymentAppender = true"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    depressed
                    fab
                    x-small
                    dark
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>결제건 추가</span>
              </v-tooltip>
              <v-card
                class="pay-form"
                v-if="paymentAppender === true"
              >
                <div class="input-wrap">
                  <div class="text-input-wrap">
                    <v-text-field
                      v-model="payerName"
                      outlined
                      dense
                      label="결제자명"
                      hide-details
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <price-text
                      v-model="payPrice"
                      outlined
                      dense
                      hide-details
                      label="금액(원)"
                      :rules="[priceRule]"
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <v-menu
                      v-model="payDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="payDate"
                          label="결제일"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          hide-details
                          color="black"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="payDate"
                        @input="payDatePicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <div class="d-flex mt-3 align-center">
                  <v-select
                    outlined
                    dense
                    label="결제방법"
                    v-model="pg.value"
                    :items="pg.data"
                    item-text="name"
                    return-object
                    hide-details
                  ></v-select>
                  <v-select
                    v-if="pg.value !== null
                    && pg.value.type === 1"
                    class="ml-2"
                    outlined
                    dense
                    label="카드사"
                    :value="company.value"
                    :items="company.data"
                    @change="company.value = $event"
                    hide-details
                    item-text="name"
                  ></v-select>
                  <v-select
                    v-if="pg.value !== null
                    && pg.value.type === 1"
                    class="ml-2"
                    outlined
                    dense
                    label="결제방식"
                    v-model="cardType.value"
                    :items="cardType.data"
                    hide-details
                    item-text="name"
                  ></v-select>
                </div>
                <div
                  class="d-flex mb-2 mt-3 align-center pay-input-wrap"
                  v-if="
                    pg.value !== null
                    && pg.value.type === 1
                    && cardType.value === 1
                  "
                >

                  <div class="text-input-wrap">
                    <v-text-field
                      v-model="cardInfo.cardNumber"
                      outlined
                      dense
                      label="카드번호"
                      :rules="[numberLengthRule(cardInfo.cardNumber, 16)]"
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <v-text-field
                      v-model="cardInfo.validThru"
                      outlined
                      dense
                      label="유효기간(MMYY)"
                      :rules="[numberLengthRule(cardInfo.validThru, 4)]"
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <v-text-field
                      v-model="cardInfo.password"
                      outlined
                      dense
                      label="비밀번호(앞두자리)"
                      :rules="[numberLengthRule(cardInfo.password, 2)]"
                    />
                  </div>
                </div>
                <div
                  class="d-flex mb-2 mt-3 align-center pay-input-wrap"
                  v-if="
                    pg.value !== null
                    && pg.value.type === 1
                  "
                >
                  <div class="text-input-wrap">
                    <v-text-field
                      v-model="cardInfo.installment"
                      outlined
                      dense
                      label="할부(개월)"
                      :rules="[installmentRule]"
                    />
                  </div>
                  <div
                    class="text-input-wrap ml-2"
                    v-if="pg.value !== null
                      && pg.value.type === 1
                      && cardType.value === 1"
                  >
                    <v-text-field
                      v-model="cardInfo.birthday"
                      outlined
                      dense
                      label="생년월일(YYMMDD)"
                      :rules="[numberLengthRule(cardInfo.birthday, 6)]"
                    />
                  </div>
                  <div class="text-input-wrap ml-2">
                    <v-text-field
                      v-model="cardInfo.phone"
                      outlined
                      dense
                      label="전화번호"
                      :rules="[phoneNumberRule]"
                    />
                  </div>
                </div>
                <div class="d-flex justify-end mt-3">
                  <v-btn
                    elevation
                    color="primary"
                    :disabled="paymentVaildChecker()"
                    @click="paymentAppend"
                  >추가</v-btn>
                  <v-btn
                    elevation
                    @click="paymentAppenderInit"
                    class="ml-2"
                  >취소</v-btn>
                </div>
              </v-card>
            </div>

            <!-- 결제정보list -->
            <div class="pay-list">
              <div
                class="pay-row"
                v-for="(row, i) in payments"
                :key="i"
              >
                <div class="pay-info">
                  <v-chip
                    small
                    label
                  >
                    {{ row.pgName }}
                  </v-chip>
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on, attrs }">
                      <v-chip
                        class="ml-1"
                        v-if="row.pgType === 1"
                        label
                        small
                        :color="row.paymentInfo.cardType === 0 ? 'success' : 'info'"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ row.paymentInfo.cardType === 0 ? 'SMS인증' : '수기결제' }}
                      </v-chip>
                    </template>
                    <span v-if="row.paymentInfo.cardType === 0">
                      {{ `카드사: ${row.paymentInfo.company}` }}<br />
                      {{ `할부: ${row.paymentInfo.installment}개월` }}<br />
                      {{ `전화번호: ${comm.makePhoneNumber(row.paymentInfo.phone)}` }}
                    </span>
                    <span v-if="row.paymentInfo.cardType === 1">
                      {{ `카드사: ${row.paymentInfo.company}` }}<br />
                      {{ `할부: ${row.paymentInfo.installment}개월` }}<br />
                      {{ `전화번호: ${comm.makePhoneNumber(row.paymentInfo.phone)}` }}<br />
                      {{ `카드번호: ${row.paymentInfo.cardNumber}` }}<br />
                      {{ `비밀번호: ${row.paymentInfo.password}` }}<br />
                      {{ `생년월일: ${row.paymentInfo.birthday}` }}<br />
                    </span>
                  </v-tooltip>
                  <span class="ml-2 pay-text">
                    {{ row.payer }}
                  </span>
                  <span class="ml-2 pay-text">
                    {{ comm.priceToString(row.amount) }}
                  </span>
                  <span class="pay-text ml-2">
                    {{ row.date }}
                  </span>
                </div>
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      x-small
                      color="error"
                      class="ml-2"
                      elevation
                      v-bind="attrs"
                      v-on="on"
                      @click="payments = payments.filter((r, index) => i !== index)"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                  <span>결제건 삭제</span>
                </v-tooltip>
              </div>
            </div>

          </div>
          <div class="total-wrap">
            <div class="total-row">
              <p class="tit">판매가</p>
              <p class="total-sub">
                {{ comm.priceToString(getTotalPrice().salesAmount * this.productQuantity) }}
              </p>
            </div>
            <div class="total-row">
              <p class="tit">결제총액</p>
              <p class="total-sub">{{ comm.priceToString(getTotalPrice().paymentsAmount) }}</p>
            </div>
            <div class="total-row">
              <p class="tit">결제차액</p>
              <p
                class="total-sub"
                :class="
                  (
                    getTotalPrice().paymentsAmount
                    - (getTotalPrice().salesAmount) * productQuantity
                  ) !== 0
                  ? 'error--text' : ''"
              >
                {{
                  comm.priceToString(
                    getTotalPrice().paymentsAmount
                    - (getTotalPrice().salesAmount * productQuantity)
                  )
                }}
              </p>
            </div>
          </div>
        </v-card-text>
        <!-- 매출등록 끝 -->

        <!-- 환불등록 시작 -->
        <v-card-text
          class="content-wrap"
          v-else-if="type === 1"
        >
          <div
            class="content-box empty-sale"
            v-if="sale === null"
          >
            <div class="empty-msg">매출정보를 불러와주세요.</div>
          </div>
          <div
            class="content-box"
            v-else
          >
            <div>
              <!-- 고객 -->
              <div class="section">
                <p class="tit">고객</p>
                <span>
                  {{ `${sale.customerName} (${sale.customerPhone.slice(-4)})` }}
                </span>
              </div>
              <div class="section">
                <p class="tit">영업담당자</p>
                <div>
                  {{ manager.fullName }}
                  <v-card
                    class="search-picker"
                    v-click-outside="managerPickerInit"
                    v-if="managerPicker === true"
                  >
                    <v-text-field
                      :autofocus="true"
                      dense
                      flat
                      hide-details
                      v-model="managerText"
                      placeholder="영업담당자 검색"
                      @input="managerFiltering"
                      color="black"
                      outlined
                      solo
                    ></v-text-field>
                    <v-list v-if="managerFilter.length > 0">
                      <v-list-item
                        v-for="(row, i) in managerFilter"
                        :key="i"
                        dense
                        nav
                        link
                        @click="manager = row, managerPickerInit()"
                      >
                        <v-list-item-title>
                          <v-chip
                            small
                            class="pr-1 pl-1 mr-1"
                            link
                            outlined
                            label
                          >
                            {{ row.group }}
                          </v-chip>
                          {{ row.name }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <v-list v-else-if="managerFilter.length === 0">
                      <v-list-item dense>
                        <v-list-item-title>{{
                          managerText == null
                          || managerText.trim().length === 0
                          ?'검색어를 입력해주세요.'
                          :'데이터가 없습니다.'
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                  <v-btn
                    small
                    elevation
                    class="mb-1 ml-2"
                    @click="getManagerList(), managerPicker = true"
                  >
                    변경
                  </v-btn>
                </div>
              </div>
              <!-- 상품 -->
              <div class="section">
                <p class="tit">상품</p>
                <div>
                  <span>{{ sale.productName }}</span>
                  <span class="ml-2">{{ comm.priceToString(sale.productPrice) }}</span>
                  <span class="ml-2">
                    {{ sale.productTerm }}개월
                    <span
                      class="info--text"
                      v-if="sale.productFreeTerm > 0"
                    >
                      + {{ sale.productFreeTerm }}개월
                    </span>
                  </span>
                </div>

              </div>
              <!-- 판매가조정 -->
              <div class="section">
                <p class="tit">판매가조정</p>
                <span>
                  {{ comm.priceToString(sale.discount) }}
                </span>
              </div>
              <!-- 서비스시작일 -->
              <div class="section">
                <p class="tit">서비스시작일</p>
                <span>
                  {{ time.makeLocalTime(sale.serviceStart, 'date') }}
                </span>
              </div>
              <div class="section-wrap">
                <div class="section">
                  <p class="tit">일할계산종료일</p>
                  <span>
                    {{
                      time.makeLocalTime(
                        time.moment(time.makeLocalTime(sale.serviceStart))
                        .add(sale.productTerm, 'M'), 'date'
                      )
                    }}
                  </span>
                </div>
              </div>
              <!-- 매출계약서 -->
              <div class="section">
                <p class="tit">매출계약서</p>
                <v-chip
                  v-if="sale.file !== null"
                  link
                  @click="fileDownload(sale.file)"
                  close
                  close-icon="mdi-download"
                  color="info"
                  @click:close="fileDownload(sale.file)"
                >{{ sale.file.name }}</v-chip>
                <span v-else>계약서 없음</span>
              </div>
              <!-- 결제건 -->
              <div
                class="section"
                :class="sale.payments.length > 0 ? 'justify-start' : '' "
              >
                <p class="tit">결제</p>
                <div
                  class="pay-list"
                  v-if="sale.payments.length > 0"
                >
                  <div
                    class="pay-row pb-0 pay-refund-row"
                    v-for="(row, i) in sale.payments"
                    :key="i"
                  >
                    <div
                      class="pay-info"
                      v-if="row.status === 1"
                    >
                      <v-chip
                        small
                        label
                      >
                        {{ row.pgName }}
                      </v-chip>
                      <v-tooltip
                        top
                        color="black"
                      >
                        <template #activator="{ on, attrs }">
                          <v-chip
                            class="ml-1"
                            v-if="row.pgType === 1"
                            label
                            small
                            :color="JSON.parse(row.paymentInfo).cardType === 0
                              ? 'success' : 'info'"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ JSON.parse(row.paymentInfo).cardType === 0 ? 'SMS인증' : '수기결제' }}
                          </v-chip>
                        </template>
                        <span v-if="JSON.parse(row.paymentInfo).cardType === 0">
                          {{ `카드사: ${JSON.parse(row.paymentInfo).company}` }}<br />
                          {{ `할부: ${JSON.parse(row.paymentInfo).installment}개월` }}<br />
                          {{ `전화번호: ${comm.makePhoneNumber(JSON.parse(row.paymentInfo).phone)}` }}
                        </span>
                        <span v-if="JSON.parse(row.paymentInfo).cardType === 1">
                          {{ `카드사: ${JSON.parse(row.paymentInfo).company}` }}<br />
                          {{ `할부: ${JSON.parse(row.paymentInfo).installment}개월` }}<br />
                          {{ `전화번호: ${comm.makePhoneNumber(JSON.parse(row.paymentInfo).phone)}` }}
                          <br />
                          {{ `카드번호: ${JSON.parse(row.paymentInfo).cardNumber}` }}<br />
                          {{ `비밀번호: ${JSON.parse(row.paymentInfo).password}` }}<br />
                          {{ `생년월일: ${JSON.parse(row.paymentInfo).birthday}` }}<br />
                        </span>
                      </v-tooltip>
                      <span class="ml-2 pay-text">
                        {{ row.payer }}
                      </span>
                      <span class="ml-2 pay-text">
                        {{ comm.priceToString(row.amount) }}
                      </span>
                      <span class="pay-text ml-2">
                        {{ time.makeLocalTime(row.date, 'date') }}
                      </span>
                      <v-chip
                        v-if="row.files.length > 0"
                        label
                        color="info ml-2"
                        small
                        close
                        close-icon="mdi-download"
                        @click="fileDownload(row.files[0])"
                        @click-close="fileDownload(row.files[0])"
                        link
                      >
                        {{ row.files[0].name }}
                      </v-chip>
                    </div>
                  </div>
                </div>
                <span v-else>결제건 없음</span>
              </div>

              <!-- 매출액 -->
              <div class="section-wrap">
                <div class="section">
                  <p class="tit">매출액</p>
                  <span>
                    {{ comm.priceToString(sale.amount) }}
                  </span>
                </div>
              </div>
              <!-- 예상환불일 -->
              <div class="section">
                <p class="tit">
                  예상환불일
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="question"
                        small
                        color="warning"
                        v-on="on"
                        v-bind="attrs"
                      >mdi-help-circle-outline</v-icon>
                    </template>
                    <span>
                      일할계산합계를 미리 계산하기기 위한 값입니다.<br />
                      ※ 실제 환불일은 담당자가 승인시 지정한 날짜로 적용됩니다.
                    </span>
                  </v-tooltip>
                </p>
                <div class="text-input-wrap ml-2">
                  <v-menu
                    v-model="refundDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="refundDate"
                        label="예상환불일"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        hide-details
                        color="black"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="refundSaleDate"
                      v-model="refundDate"
                      @input="refundDatePicker = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <!-- 일할계산합계 -->
              <div class="total-wrap">
                <div class="total-row">
                  <p class="tit">
                    일할계산합계
                    <v-tooltip
                      top
                      color="black"
                    >
                      <template #activator="{ on, attrs }">
                        <v-icon
                          class="question"
                          small
                          color="warning"
                          v-on="on"
                          v-bind="attrs"
                        >mdi-help-circle-outline</v-icon>
                      </template>
                      <span>
                        기존매출정보와 예상환불일을 기준으로 일할계산된 금액입니다.<br />
                        ※ 실제 환불액은 계좌환불/카드취소건의 합으로 집계됩니다.
                      </span>
                    </v-tooltip>
                  </p>
                  <p class="total-result">
                    {{ comm.priceToString(getEstRefundAmount(sale).refundPrice) }}
                    <v-tooltip
                      top
                      color="black"
                    >
                      <template #activator="{ on, attrs }">
                        <v-icon
                          class="question ml-1"
                          small
                          color="warning"
                          v-on="on"
                          v-bind="attrs"
                        >mdi-help-circle-outline</v-icon>
                      </template>
                      <span>
                        서비스시작일: {{ getEstRefundAmount(sale).saleDate }}<br />
                        일할계산종료일: {{ getEstRefundAmount(sale).endDate }}<br />
                        예상환불일: {{ getEstRefundAmount(sale).date }}<br />
                        이용일수: {{ getEstRefundAmount(sale).diffDays }}일<br />
                        일할계산잔여일수: {{ getEstRefundAmount(sale).remainDays }}일<br />
                        일할계산(1일): {{
                          comm.priceToString(getEstRefundAmount(sale).dayPrice)
                        }}(소수점버림)<br />
                        일할계산(합계): {{
                          comm.priceToString(getEstRefundAmount(sale).refundPrice)
                        }}<br />
                      </span>
                    </v-tooltip>
                  </p>
                </div>
              </div>

              <!-- 환불정보등록 -->
              <div
                class="section"
                v-if="sale.method === 0"
              >
                <p class="tit">
                  환불계좌정보
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="question"
                        small
                        color="warning"
                        v-on="on"
                        v-bind="attrs"
                      >mdi-help-circle-outline</v-icon>
                    </template>
                    <span>
                      입력한 환불정보로 담당자가 계좌입금을 진행합니다.<br />
                      계좌환불이 필요한 경우 정보를 입력해주세요.
                    </span>
                  </v-tooltip>
                </p>
                <v-tooltip
                  top
                  color="black"
                  v-if="payments.length < 5"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      @click="paymentAppenderInit(); paymentAppender = true"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      depressed
                      fab
                      x-small
                      dark
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>환불계좌정보 추가</span>
                </v-tooltip>
                <v-card
                  class="pay-form"
                  v-if="paymentAppender === true"
                >
                  <div class="input-wrap">
                    <div class="text-input-wrap">
                      <v-text-field
                        v-model="payerName"
                        outlined
                        dense
                        label="예금주"
                        hide-details
                      />
                    </div>
                    <div class="text-input-wrap ml-2">
                      <price-text
                        v-model="payPrice"
                        outlined
                        dense
                        hide-details
                        label="금액(원)"
                        :rules="[priceRule]"
                      />
                    </div>
                    <div class="text-input-wrap ml-2">
                      <v-select
                        outlined
                        dense
                        label="은행"
                        v-model="bank.value"
                        :items="bank.data"
                        item-text="name"
                        hide-details
                      ></v-select>
                    </div>
                  </div>
                  <div class="d-flex mt-3 align-center">
                    <v-text-field
                      v-model="bankAccount"
                      outlined
                      dense
                      label="계좌번호"
                      :rules="[bankAccountRule(bankAccount)]"
                    />
                  </div>
                  <div class="d-flex justify-end mt-3">
                    <v-btn
                      elevation
                      color="primary"
                      :disabled="paymentVaildChecker('refund')"
                      @click="paymentAppend('refund')"
                    >추가</v-btn>
                    <v-btn
                      elevation
                      @click="paymentAppenderInit"
                      class="ml-2"
                    >취소</v-btn>
                  </div>
                </v-card>
              </div>

              <!-- 환불정보list -->
              <div class="pay-list">
                <div
                  class="pay-row"
                  v-for="(row, i) in payments"
                  :key="i"
                >
                  <div class="pay-info">
                    <v-chip
                      small
                      label
                    >
                      {{ row.paymentInfo.bank }}
                    </v-chip>
                    <span class="ml-2 pay-text">
                      {{ row.paymentInfo.account }}
                    </span>
                    <span class="ml-2 pay-text">
                      {{ row.payer }}
                    </span>
                    <span class="pay-text ml-2">
                      {{ comm.priceToString(row.amount * -1) }}
                    </span>
                  </div>
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        fab
                        x-small
                        color="error"
                        class="ml-2"
                        elevation
                        v-bind="attrs"
                        v-on="on"
                        @click="payments = payments.filter((r, index) => i !== index)"
                      >
                        <v-icon small>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <span>결제건 삭제</span>
                  </v-tooltip>
                </div>
              </div>
            </div>

          </div>
        </v-card-text>
        <!-- 환불등록 끝 -->

        <!-- 매출등록 버튼 -->
        <v-card-actions
          class="pb-6 pr-6 pt-0"
          v-if="type === 0"
        >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            @click="registSales"
            :disabled="
              product === null
              || customer === null
              || Number.isNaN(discount * discountRadio)
            "
          >
            등록
          </v-btn>
        </v-card-actions>

        <!-- 환불등록 버튼 -->
        <v-card-actions
          class="pb-6 pr-6 pt-0"
          v-if="type === 1 && sale !== null"
        >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            @click="registRefund"
          >
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import time from '@/util/time';
import comm from '@/util/comm';
import fileUtils from '@/util/file';
import PriceText from '../input/PriceText.vue';

export default {
  name: 'DlgSalesRegist',
  components: {
    PriceText,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    isManager: {
      type: Boolean,
      default: false,
    },
    isLeader: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    comm,
    priceRule: (value) => {
      const val = Number(value.replaceAll(',', ''));
      if (val < 0 || val > 1000000000 || value === 'NaN') {
        return '0원 이상 10억 미만이어야 합니다.';
      }
      return true;
    },
    numberLengthRule: (value, leng) => {
      if (value === null || Number.isNaN(Number(value)) === true || value.length !== leng) {
        return `${leng}자리의 숫자로만 입력해주세요.`;
      }
      return true;
    },
    bankAccountRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true
        || value.length > 16 || value.length < 10) {
        return '10 ~ 16자리의 숫자로만 입력해주세요.';
      }
      return true;
    },
    installmentRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true || value > 48 || value < 0) {
        return '0 ~ 48 사이의 숫자로 입력해주세요.';
      }
      return true;
    },
    quantityRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true || value > 9999 || value <= 0) {
        return '1 ~ 9999 사이의 숫자로 입력해주세요.';
      }
      return true;
    },
    phoneNumberRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true
        || value.length > 11 || value.length < 10) {
        return '10자리 혹은 11자리의 숫자로만 입력해주세요.';
      }
      return true;
    },
    groups: [],
    time,
    fileUtils,
    saleDoc: null,
    customerText: null,
    customers: [],
    customerPicker: false,
    customer: null,
    productText: null,
    products: [],
    productFilter: [],
    productPicker: false,
    product: null,
    productQuantity: 1,
    discount: 0,
    discountRadio: -1,
    methodRadio: 0,
    saleText: null,
    sales: [],
    salesPicker: false,
    sale: null,
    pg: {
      data: [],
      value: null,
    },
    productTypes: ['리딩', '코인'],
    company: {
      data: ['NH농협', '국민', '농협', '롯데', '비씨', '삼성', '신한', '씨티', '우리', '전북', '하나', '현대'],
      value: 'NH농협',
    },
    bank: {
      data: [
        'KEB하나은행',
        'SC제일은행',
        '국민은행',
        '신한은행',
        '외환은행',
        '우리은행',
        '한국시티은행',
        '경남은행',
        '광주은행',
        '대구은행',
        '부산은행',
        '전북은행',
        '제주은행',
        '기업은행',
        '농협',
        '수협',
        '한국산업은행',
        '한국수출입은행',
        '기타',
      ],
      value: 'KEB하나은행',
    },
    bankAccount: null,
    cardType: {
      data: [
        { name: 'SMS인증', value: 0 },
        { name: '수기결제', value: 1 },
      ],
      value: 0,
    },
    cardInfo: {
      cardNumber: null,
      validThru: null,
      installment: null,
      password: null,
      phone: null,
      birthday: null,
    },
    paymentAppender: false,
    payDatePicker: false,
    payDate: time.moment().format('YYYY-MM-DD'),
    payerName: '',
    payPrice: 0,
    payments: [],
    managers: [],
    managerText: null,
    managerFilter: [],
    manager: null,
    managerPicker: false,
    preService: false,
    refundSaleDatePicker: false,
    refundSaleDate: time.moment().format('YYYY-MM-DD'),
    refundDatePicker: false,
    refundDate: time.moment().format('YYYY-MM-DD'),
    refundSaleDoc: null,
    refundSaleAmount: 0,
    refundDocs: [],
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.$emit('update:show', false);
      this.discount = 0;
      this.discountRadio = -1;
      this.methodRadio = 0;
      this.file = null;
      this.refundDatePicker = false;
      this.refundDate = time.moment().format('YYYY-MM-DD');
      this.payments = [];
      this.preService = false;
      this.paymentAppenderInit();
      this.salesPickerInit();
      this.sale = null;
      this.customerPickerInit();
      this.customer = null;
      this.productPickerInit();
      this.product = null;
      this.managerPickerInit();
      this.manager = null;
      this.saleDoc = null;
      this.refundSaleDoc = null;
    },
    paymentAppenderInit() {
      this.paymentAppender = false;
      this.payDatePicker = false;
      this.payDate = time.moment().format('YYYY-MM-DD');
      this.payerName = '';
      this.payPrice = 0;
      this.cardType.value = 0;
      this.getPgList(this.methodRadio);
      this.company.value = 'NH농협';
      this.bank.value = 'KEB하나은행';
      this.bankAccount = null;
      this.cardInfo = {
        cardNumber: null,
        validThru: null,
        installment: null,
        password: null,
        phone: null,
        birthday: null,
      };
    },
    salesPickerInit() {
      this.saleText = null;
      this.sales = [];
      this.salesPicker = false;
    },
    customerPickerInit() {
      this.customerText = null;
      this.customers = [];
      this.customerPicker = false;
    },
    productPickerInit() {
      this.productText = null;
      this.products = [];
      this.productFilter = [];
      this.productPicker = false;
      this.productQuantity = 1;
    },
    managerPickerInit() {
      this.managersText = null;
      this.managers = [];
      this.managerFilter = [];
      this.managerPicker = false;
    },
    refundSaleDatePickerInit() {
      this.refundSaleDatePicker = false;
      this.refundSaleDate = time.moment().format('YYYY-MM-DD');
    },
    getCustomerList() {
      this.customers = [];
      if (this.customerText === null || this.customerText.length <= 0) {
        this.customers = [];
      } else if (this.customerText.length < 2 || this.customerText === '010') {
        this.alert(['error', '2자 이상이며 \'010\'이 아닌 검색어를 입력해주세요.']);
      } else {
        this.progress(true);
        const filters = [];
        const customerWhere = {
          filters: [
            {
              condition: 'inc',
              column: 'name',
              value: this.customerText,
            },
            {
              where: 'or',
              condition: 'inc',
              column: 'phone',
              value: this.customerText,
            },
          ],
        };
        if (this.isManager === true) {
          filters.push({
            condition: 'eq',
            column: 'manager',
            value: this.userInfo.id,
          });
          customerWhere.where = 'and';
        } else if (this.isLeader === true) {
          const allChildrens = this.userInfo.leaderGroups
            .map((r) => comm.getGroupChildrens(this.groups, r.no));
          const childrens = [...new Set([].concat(...allChildrens))];
          childrens.forEach((r, i) => {
            const obj = {
              condition: 'eq',
              column: 'managerGroupNo',
              value: r,
            };
            if (i > 0) obj.where = 'or';
            filters.push(obj);
          });
          customerWhere.where = 'and';
        }
        this.$socket.emit('users.customer.list.min.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: [],
          sortDesc: [],
          filters: [
            ...filters,
            customerWhere,
          ],
        }, async (resp) => {
          if (resp.result === 'success') {
            this.customers = Object.freeze(JSON.parse(await fileUtils.buffersToJson(resp.items)));
            this.progress(false);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.progress(false);
          }
        });
      }
    },
    getProductList() {
      this.products = [];
      this.productFilter = [];
      this.productText = null;
      this.progress(true);
      this.$socket.emit('products.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [
          {
            condition: 'eq',
            column: 'disabled',
            value: false,
          },
        ],
      }, (resp) => {
        if (resp.result === 'success') {
          this.products = resp.items;
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },

    getPgList(method = null) {
      this.pg = {
        data: [],
        value: null,
      };
      this.$socket.emit('pg.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [{
          condition: 'eq',
          column: 'disabled',
          value: 0,
        }],
      }, (resp) => {
        if (resp.result === 'success') {
          if (method === null) {
            this.pg.data = resp.items;
          } else {
            this.pg.data = resp.items.filter((r) => r.type === method);
          }
          this.pg.value = this.pg.data.find((r, i) => i === 0);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.paymentAppender = false;
        }
      });
    },
    managerFiltering(e) {
      if (e != null && e.length > 0) {
        const result = this.managers.filter((r) => r.fullName.indexOf(e) !== -1);
        this.managerFilter = result;
      } else {
        this.managerFilter = [];
      }
    },
    productFiltering(e) {
      if (e != null && e.length > 0) {
        const result = this.products.filter((r) => r.name.indexOf(e) !== -1);
        this.productFilter = result;
      } else {
        this.productFilter = [];
      }
    },

    paymentVaildChecker(type = null) {
      if (this.payerName.length <= 0 || this.payPrice <= 0) {
        return true;
      }
      if (
        type === 'refund'
        && (
          this.bankAccount === null
          || this.bankAccount.length > 16
          || this.bankAccount.length < 10
          || Number.isNaN(Number(this.bankAccount)) === true
        )
      ) {
        return true;
      }
      if (this.methodRadio === 1 && this.pg.value.type === 1) {
        if (this.cardInfo.installment === null
          || Number.isNaN(Number(this.cardInfo.installment)) === true
          || this.cardInfo.installment < 0
          || this.cardInfo.installment > 48) return true;
        if (this.cardInfo.phone === null
          || Number.isNaN(Number(this.cardInfo.phone)) === true
          || this.cardInfo.phone.length < 10
          || this.cardInfo.phone.length > 11) return true;
        if (this.cardType.value === 1) {
          if (this.cardInfo.cardNumber === null
            || Number.isNaN(Number(this.cardInfo.cardNumber)) === true
            || this.cardInfo.cardNumber.length !== 16) return true;
          if (this.cardInfo.validThru === null
            || Number.isNaN(Number(this.cardInfo.validThru)) === true
            || this.cardInfo.validThru.length !== 4) return true;
          if (this.cardInfo.password === null
            || Number.isNaN(Number(this.cardInfo.password)) === true
            || this.cardInfo.password.length !== 2) return true;
          if (this.cardInfo.birthday === null
            || Number.isNaN(Number(this.cardInfo.birthday)) === true
            || this.cardInfo.birthday.length !== 6) return true;
        }
      }
      return false;
    },
    paymentAppend(type = null) {
      const paymentInfo = {};
      let payItem = {};
      if (type === 'refund') {
        payItem = {
          payer: this.payerName,
          amount: this.payPrice * -1,
          paymentInfo: {
            bank: this.bank.value,
            account: this.bankAccount,
          },
          files: [],
        };
      } else {
        if (this.pg.value.type === 1) {
          paymentInfo.company = this.company.value;
          paymentInfo.phone = this.cardInfo.phone;
          paymentInfo.installment = Number(this.cardInfo.installment);
          paymentInfo.cardType = this.cardType.value;
          if (this.cardType.value === 1) {
            paymentInfo.birthday = this.cardInfo.birthday;
            paymentInfo.cardNumber = this.cardInfo.cardNumber;
            paymentInfo.validThru = this.cardInfo.validThru;
            paymentInfo.password = this.cardInfo.password;
          }
        }
        payItem = {
          pg: this.pg.value.no,
          payer: this.payerName,
          amount: this.payPrice,
          date: this.payDate,
          paymentInfo,
          files: [],
          pgName: this.pg.value.name,
          pgType: this.pg.value.type,
        };
      }

      this.payments.push(payItem);
      this.paymentAppenderInit();
    },
    customerSetter(customer) {
      this.customerPickerInit();
      const groupName = this.comm.getGroupFullPath(this.groups, customer.managerGroupNo, '그룹없음 ');
      const manager = {
        id: customer.manager,
        fullName: `${groupName}${customer.managerName}`,
        name: customer.managerName,
        group: groupName,
        groupNo: customer.managerGroupNo,
      };
      this.manager = manager;
      this.customer = customer;
    },
    async fileSetter(e, target) {
      const countMsg = {
        saleDoc: '계약서는 1개만 등록가능합니다.',
        refundSaleDoc: '매출계약서는 1개만 등록가능합니다.',
      };
      if (e.target.files.length > 1) {
        this.alert(['error', countMsg[target]]);
      } else if (e.target.files[0].type.indexOf('image') === -1
        && e.target.files[0].type.indexOf('pdf') === -1) {
        this.alert(['error', '이미지 파일 및 PDF 파일만 업로드 가능합니다.']);
      } else if (e.target.files.length > 0) {
        this.progress(true);
        const sizeCheck = e.target.files[0].size < 10485760;
        if (sizeCheck === true) {
          const getFiles = await this.fileUtils.readFileToPayload(e.target.files[0]);
          this.$refs[`${target}Input`].value = null;
          if (target === 'refundDoc') {
            this.refundDocs.push(getFiles);
          } else {
            this[target] = getFiles;
          }
        } else {
          this.alert(['error', '파일당 최대 10MB 까지 업로드 가능합니다.']);
        }
        this.progress(false);
      }
    },
    fileDownload(fileObj) {
      this.progress(true);
      this.$socket.emit('files.get', {
        no: fileObj.no,
        token: fileObj.token,
      }, async (resp) => {
        if (resp.result === 'success') {
          await fileUtils.writeFileFromPayload(resp.item);
          this.progress(false);
        } else if (resp.name === 'TokenExpiredError') {
          this.alert(['error', '파일 다운로드 시간이 만료되었습니다. 새로고침 후 다시 시도해주세요.']);
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    getTotalPrice() {
      let result = {
        salesAmount: 0,
        paymentsAmount: 0,
      };
      if (this.type === 0) {
        const productPrice = this.product === null ? 0 : this.product.price;
        const salesAmount = productPrice + (this.discount * this.discountRadio);
        result.salesAmount = Number.isNaN(salesAmount) ? 0 : salesAmount;
        result.paymentsAmount = this.payments.reduce((acc, r) => acc + r.amount, 0);
      } else if (this.type === 1) {
        const restDays = this.getRestDays();
        const serviceDays = time.moment(time.makeLocalTime(this.sale.date))
          .add(this.sale.productTerm, 'M')
          .diff(time.moment(time.makeLocalTime(this.sale.date)), 'days');
        const oneDayPrice = (this.sale.productPrice + this.sale.discount) / serviceDays;
        const amount = Math.round(oneDayPrice * restDays);
        result = Number.isNaN(amount) ? 0 : amount;
      }
      return result;
    },
    registSales() {
      const discount = this.discount * this.discountRadio;
      const obj = {
        product: Number(this.product.no),
        customer: this.customer.id,
        manager: this.manager.id,
        group: this.manager.groupNo === null ? null : Number(this.manager.groupNo),
        quantity: this.product.type === 0 ? 1 : Number(this.productQuantity),
        discount: this.product.price + discount <= 0 ? this.product.price * -1 : discount,
        payments: this.payments,
        file: this.saleDoc,
        provisionalPayment: false,
        method: this.methodRadio,
      };
      const func = () => {
        this.progress(true);
        this.$socket.emit('sales.add', obj, (resp) => {
          if (resp.result === 'success') {
            this.alert(['success', '매출이 등록되었습니다.']);
            this.confirm({ show: false });
            this.init();
            this.refresh();
            this.progress(false);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            this.confirm({ show: false });
            console.error(resp);
          }
          this.progress(false);
        });
      };
      if (this.preService === true) {
        this.confirm({
          show: true,
          func,
          msg: `가결제로 등록시 고객에게 서비스가 즉시 제공되며
            결제승인 이전까지 매출집계에 포함되지 않습니다.
            <br/><br/>가결제로 등록할까요?`,
          btnText: '등록',
        });
      } else {
        func();
      }
    },
    getGroupList() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    getManagerList() {
      this.managers = [];
      this.managerText = null;
      this.managerFilter = [];
      this.progress(true);
      this.$socket.emit('users.staffs.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['groupNo'],
        sortDesc: [false],
        filters: [{ condition: 'eq', column: 'is_sales', value: 1 }],
      }, (resp) => {
        if (resp.result === 'success') {
          resp.items.forEach((r) => {
            const groupName = this.comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
            const manager = {
              id: r.id,
              fullName: `${groupName}${r.name}`,
              name: r.name,
              group: groupName,
              groupNo: r.groupNo,
            };
            this.managers.push(manager);
          });
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    getSalesList() {
      if (this.saleText.length <= 0) {
        this.sales = [];
      } else {
        this.progress(true);
        const filters = [{
          condition: 'eq',
          column: 'status',
          value: 1,
        },
        {
          where: 'and',
          condition: 'eq',
          column: 'productType',
          value: 0,
        },
        {
          where: 'and',
          condition: 'eq',
          column: 'type',
          value: 0,
        }];
        if (this.isManager === true) {
          filters.push({
            where: 'and',
            condition: 'eq',
            column: 'manager',
            value: this.userInfo.id,
          });
        } else if (this.isLeader === true) {
          const groupFilters = {
            where: 'and',
            filters: [],
          };
          const allChildrens = this.userInfo.leaderGroups
            .map((r) => comm.getGroupChildrens(this.groups, r.no));
          const childrens = [...new Set([].concat(...allChildrens))];
          childrens.forEach((r, i) => {
            const obj = {
              condition: 'eq',
              column: 'groupNo',
              value: r,
            };
            if (i > 0) obj.where = 'or';
            groupFilters.filters.push(obj);
          });
          filters.push(groupFilters);
        }
        filters.push({
          where: 'and',
          filters: [
            {
              condition: 'inc',
              column: 'customerName',
              value: this.saleText,
            },
            {
              where: 'or',
              condition: 'eq',
              column: 'no',
              value: Number.isNaN(Number(this.saleText)) ? '' : Number(this.saleText),
            },
          ],
        });
        this.$socket.emit('sales.list.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: ['date'],
          sortDesc: [true],
          filters,
        }, (resp) => {
          if (resp.result === 'success') {
            this.sales = resp.items;
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
          this.progress(false);
        });
      }
    },
    getRestDays() {
      if (this.sale !== null) {
        const diff = time.moment(time.makeLocalTime(this.sale.date))
          .add(this.sale.productTerm, 'M')
          .diff(this.refundDate, 'days');
        return diff < 0 ? 0 : diff;
      }
      return 0;
    },
    setSales(no) {
      this.progress(true);
      this.$socket.emit('sales.get', { no }, (resp) => {
        if (resp.result === 'success') {
          this.sale = resp.item;
          const groupName = this.comm.getGroupFullPath(
            this.groups, this.sale.managerGroupNo, '그룹없음',
          );
          this.manager = {
            id: this.sale.manager,
            fullName: `${groupName}${this.sale.managerName}`,
            name: this.sale.managerName,
            group: groupName,
            groupNo: this.sale.managerGroupNo,
          };
          this.salesPickerInit();
          this.customerPickerInit();
          this.managerPickerInit();
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    getEstRefundAmount(sale) {
      const saleDate = time.moment(time.makeLocalTime(sale.serviceStart, 'date')).format('YYYY-MM-DD');
      const endDate = time.moment(saleDate).add(sale.productTerm, 'M').format('YYYY-MM-DD');
      const serviceTerm = time.moment(endDate).diff(saleDate, 'days');
      const diffDays = time.moment(this.refundDate).diff(saleDate, 'days') >= 0
        ? time.moment(this.refundDate).diff(saleDate, 'days') : 0;
      const dayPrice = Math.floor(Number(sale.amount) / serviceTerm);
      const refundPrice = (Number(sale.amount) / serviceTerm) * (serviceTerm - diffDays);
      return {
        saleDate, // 매출일
        endDate, // 일할계산종료일
        serviceTerm, // 일할계산일수
        date: this.refundDate, // 환불일
        diffDays, // 서비스이용일수
        remainDays: serviceTerm - diffDays > 0 ? serviceTerm - diffDays : 0, // 잔여일수
        dayPrice, // 일할계산합계(1일)
        refundPrice: refundPrice > 0 ? Math.round(refundPrice) : 0, // 일할계산합계(잔여합계)
      };
    },
    registRefund() {
      const obj = {
        origin: this.sale.no,
        customer: this.sale.customer,
        manager: this.manager.id,
        discount: 0,
        method: this.sale.method,
        group: this.manager.groupNo,
        quantity: this.sale.quantity * -1,
        payments: this.payments,
        product: this.sale.productNo,
      };
      console.log(obj);
      // this.progress(true);
      // this.$socket.emit('sales.refund.add', obj, (resp) => {
      //   if (resp.result === 'success') {
      //     this.alert(['success', '환불이 등록되었습니다.']);
      //     this.confirm({ show: false });
      //     this.init();
      //     this.refresh();
      //     this.progress(false);
      //   } else {
      //     this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
      //     this.confirm({ show: false });
      //     console.error(resp);
      //   }
      //   this.progress(false);
      // });
    },
    setProduct(product) {
      if (product.type === 0) { // 리딩일떄
        this.progress(true);
        this.$socket.emit('rooms.list.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: ['no'],
          sortDesc: [true],
          filters: [{
            condition: 'eq',
            column: 'disabled',
            value: 0,
          }, {
            where: 'and',
            condition: 'eq',
            column: 'productNo',
            value: product.no,
          }],
        }, (resp) => {
          if (resp.result === 'success') {
            this.product = {
              ...product,
              rooms: [...resp.items],
            };
            this.progress(false);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.productPickerInit();
            this.progress(false);
          }
        });
      } else {
        this.product = product;
        this.payments = [];
        this.methodRadio = 0;
      }
    },
    checkFuncPermission(funcName) {
      try {
        const userPermission = { ...this.userInfo.permission.sales.menu.sales.func };
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getGroupList();
        }
      },
    },
    show: {
      handler(val) {
        if (val === true) {
          this.getGroupList();
        }
      },
    },
    methodRadio: {
      handler() {
        this.payments = [];
      },
    },
  },
};
</script>
<style scoped lang="scss">
.content-wrap {
  .content-box {
    border-top: 1px solid #ccc;
    margin-top: 10px;
    &.empty-sale {
      border-top: 0;
      .empty-msg {
        background-color: #f2f2f2;
        color: #555;
        font-size: 1rem;
        padding: 75px 30px;
        border-radius: 4px;
        text-align: center;
      }
    }
    .pay-list {
      .pay-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        padding-top: 0px;
        &.pay-refund-row + .pay-refund-row {
          padding-top: 4px;
        }
        .pay-info {
          display: flex;
          align-items: center;
          .pay-text {
            color: #262626;
          }
        }
      }
    }
    .section {
      position: relative;
      padding: 10px;
      display: flex;
      align-items: center;
      color: #333;
      justify-content: space-between;
      & + .section,
      & + .section-wrap {
        border-top: 1px dashed #ccc;
      }
      .pay-form {
        padding: 10px;
        min-width: 640px;
        max-width: 640px;
        position: absolute;
        right: 30px;
        z-index: 1;
        top: -150px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
          0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        .v-list {
          max-height: 100px;
          overflow: auto;
        }
        &.search-picker-wide {
          min-width: 300px;
          max-width: 800px;
        }
      }
      .search-picker {
        width: 250px;
        max-width: 250px;
        position: absolute;
        right: 30px;
        z-index: 1;
        top: 8px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
          0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        .v-list {
          max-height: 300px;
          overflow: auto;
        }
        &.search-picker-wide {
          min-width: 300px;
          max-width: 800px;
        }
      }
    }
    .section-wrap {
      & + .section,
      & + .section-wrap {
        border-top: 1px dashed #ccc;
      }
      .section + .section {
        border-top: 1px dashed #ccc;
      }
    }
    .tit {
      font-size: 0.9rem;
      font-weight: 500;
      color: #262626;
      margin: 0;
      margin-right: 10px;
    }
  }
}
.refund-file-wrap {
  max-width: 640px;
  .refund-files {
    max-width: 600px;
    flex-wrap: wrap;
  }
}
.total-wrap {
  border-top: 1px dashed #ccc;
  padding: 10px 0px;
  .total-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    &.total-row-dot {
      border-bottom: 1px dashed #ccc;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
    .tit {
      font-size: 0.9rem;
      font-weight: 500;
      color: #262626;
      margin: 0;
      margin-right: 10px;
    }
    .total-result {
      font-weight: 500;
      font-size: 1.1rem;
      color: #262626;
      margin: 0;
    }
    .total-sub {
      font-size: 0.9rem;
      color: #262626;
      margin: 0;
    }
  }
}
.input-wrap {
  display: flex;
  align-items: center;
  .text-input-wrap {
    width: 200px;
  }
}
.pay-input-wrap {
  .text-input-wrap {
    width: 100%;
  }
}
.search-btn {
  position: absolute;
  top: 6px;
  right: 6px;
}
.file-input {
  display: none;
}
.picker-wrap {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  .search-picker {
    min-width: 300px;
    max-width: 800px;
    position: absolute;
    right: 60px;
    z-index: 1;
    top: -2px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    .v-list {
      max-height: 200px;
      overflow: auto;
    }
  }
}
</style>
